import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

// Firebase Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';

import 'hammerjs';
import 'mousetrap';
import { AccountComponent } from './account/account.component';
import { ServiceWorkerModule } from '@angular/service-worker';

import { LOCALE_ID } from '@angular/core';

import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';

import { registerLocaleData } from '@angular/common';
import localeSl from '@angular/common/locales/sl';
import localeSlExtra from '@angular/common/locales/extra/sl';
import { AuthGuard } from './shared/services/auth.guard';
import { AdminAuthGuard } from './shared/services/admin-auth.guard';

registerLocaleData(localeSl, localeSlExtra);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'https://shop.mebis.si' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: "bottom",
  palette: {
    popup: {
      background: "#000000",
      text: "#ffffff",
      link: "#ffffff"
    },
    button: {
      background: "#f1d600",
      text: "#000000",
      border: "transparent",
    }
  },
  theme: 'classic',
  type: 'info',
  content: {
    href: "https://shop.mebis.si/pages/politika-zasebnosti"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    AccountComponent,
    PagesComponent,
    ElementsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireFunctionsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgbModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    SharedModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    AuthGuard,
    AdminAuthGuard,
    ScreenTrackingService,
    UserTrackingService,
    { provide: REGION, useValue: 'europe-west1' },
    { provide: LOCALE_ID, useValue: 'sl-SI' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
