<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>{{ 'header.title' | translate }}</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>{{ 'header.call-us' | translate }} +386 40 188 926</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-right">
          <ul class="header-dropdown">
            <li class="compare">
              <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>{{ 'header.compare' | translate }}</a>
            </li>
            <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>{{ 'header.wishlist' | translate }}</a>
            </li>
            <li class="onhover-dropdown mobile-account">

              <div *ngIf="auth.user$ | async; then authenticated else guest">
                <!-- template will replace this div -->
              </div>

              <!-- User NOT logged in -->
              <ng-template #guest>
                <i class="fa fa-user" aria-hidden="true"></i>{{ 'account.user' | translate }}
                <ul class="onhover-show-div">
                  <li>
                    <a data-lng="en" [routerLink]="['/account/login']">
                      {{ 'account.login' | translate }}
                    </a>
                  </li>
                  <li>
                    <a data-lng="en" [routerLink]="['/account/register']">
                      {{ 'account.register' | translate }}
                    </a>
                  </li>
                </ul>
              </ng-template>

              <!-- User logged in -->
              <ng-template #authenticated>
                <div *ngIf="auth.user$ | async as user">
                  <i class="fa fa-user" aria-hidden="true"></i> {{ user.firstName + " " + user.lastName }}
                  <ul class="onhover-show-div">
                    <li>
                      <a [routerLink]="['/account/dashboard']">
                        {{ 'account.my-profile' | translate }}
                      </a>
                    </li>
                    <li>
                      <a data-lng="es" (click)="auth.signOut()">
                        {{ 'account.logout' | translate }}
                      </a>
                    </li>
                  </ul>
                </div>
              </ng-template>

            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <!-- <app-left-menu></app-left-menu> -->
            <!-- <div class="brand-logo"> -->
              <div>
              <a routerLink="/home" id="headerlogo">
                <img src="assets/images/mebis/homepagelogo03.png" class="img-fluid logo" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
