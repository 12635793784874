import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService} from './auth.service'
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';

@Injectable()
export class AdminAuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.auth.user$.pipe(
      take(1),
      map(user =>  user.email.includes('@mebis.si') || user.email.includes('@varanox.com')), // <-- map to boolean
      tap(loggedIn => {
        if (!loggedIn) {
          // console.log('access denied')
          this.router.navigate(['/']);
        }
      })
    )
  }
}