

<!-- /call_to_action start -->
<section id="new-project" class="new-project">
  <div class="container">
      <div class="new-project-details">
          <div class="row">
              <div class="col-lg-8 col-md-12">
                  <div class="single-new-project">
                      <h3> {{ 'footer.where-to' | translate }} <strong>{{ 'footer.find-us' | translate }}</strong>? </h3>
                  </div>
              </div>
              <div class="col-lg-3 col-md-12">
                <div class="single-new-project"><a href="https://mebis.si/kontakt.html" class="slide-btn"> <strong>{{ 'footer.contact-us' | translate }}
                  <i class="fa fa-angle-double-right" aria-hidden="true"></i></strong></a></div>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- /call_to_action end -->


<!--footer section -->
<footer [class]="class">
	<section class="section-b-space">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-2 col-md-3">
					<div class="footer-title footer-mobile-title">
						<h4>{{ 'about-us' | translate }}</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" class="footer-logo" alt="logo">
            </div>
						<div class="footer-social">
                <ul>
                    <li>
                        <a href="https://www.facebook.com/info.mebis/"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                    </li>
                    <li>
                        <a href="https://si.linkedin.com/in/mebis-d-o-o-b8b2521aa"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                    </li>
                    <li>
                        <a href="#"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                    </li>
                </ul>
            </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
              <h4>{{ 'footer.category' | translate }}</h4>
            </div>
            <div class="footer-contant">
                <ul>
                  <li><a href="javascript:void(0)" [routerLink]="['/shop/collection']" 
                    [queryParams]="{category: 'Delovna oblačila'}">{{ 'footer.working-clothes' | translate }}</a>
                  </li>
                  <li><a href="javascript:void(0)" [routerLink]="['/shop/collection']" 
                    [queryParams]="{category: 'Delovni čevlji'}">{{ 'footer.working-shoes' | translate }}</a>
                  </li>
                </ul>
            </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                <h4>{{ 'footer.quick-links' | translate }}</h4>
            </div>
            <div class="footer-contant">
                <ul>
                    <li><a href="https://mebis.si/storitve01.html">{{ 'footer.services' | translate }}</a></li>
                    <li><a href="https://mebis.si/podjetje.html">{{ 'footer.company' | translate }}</a></li>
                    <li><a href="https://shop.mebis.si/home">{{ 'footer.website-shop' | translate }}</a></li>
                    <li><a href="https://mebis.si/reference.html">{{ 'footer.references' | translate }}</a></li>
                    <li><a href="https://mebis.si/kontakt.html">{{ 'footer.contact' | translate }}</a></li>
                </ul>
            </div>
					</div>
        </div>
        <div class="col">
					<div class="sub-title">
						<div class="footer-title">
                <h4>{{ 'footer.conditions' | translate }}</h4>
            </div>
            <div class="footer-contant">
              <ul>
                <li><a [routerLink]="'/pages/politika-zasebnosti'">{{ 'cookie.private-policy' | translate }}</a></li>
                <li><a [routerLink]="'/pages/pogoji-poslovanja'">{{ 'footer.terms-of-business' | translate }}</a></li>
              </ul>
            </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>mebis d.o.o.</h4>
						</div>
						<div class="footer-contant">
              <ul class="contact-list">
                  <li>
                    <i class="fa fa-map-marker icon-color"></i>
                    Celjska cesta 30 <br />
                    3250 Rogaška Slatina
                  </li>
                  <li><i class="fa fa-phone icon-color"></i>+386 40 188 926</li>
                  <li><i class="fa fa-envelope-o icon-color"></i><a>info@mebis.si</a></li>
              </ul>
						</div>
					</div>
        </div>
        <div class="col">
					<div class="footer-contant">
						<div class="footer-logo">
							<img src="assets/images/mebis/unicert.png" class="footer-unicert" alt="logo">
						</div>
					</div>
				</div>
			</div>
		</div>
  </section>
  <div class="footer_bottom">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="copyright">
                    <p>{{ "copyright" | translate }}</p>
                </div>
            </div>
        </div>
    </div>
  </div>
</footer>
<!--footer section end -->
