import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public products: Product[] = []
  
  public languages = [
    { 
      name: 'Slovenščina',
      code: 'sl'
    },
    { 
      name: 'Hrvatski',
      code: 'hr'
    },
    { 
      name: 'Deutsche',
      code: 'de'
    }
  ];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 1 // price of euro
  }
  // {
  //   name: 'Kuna',
  //   currency: 'HR',
  //   price: 7 // price of Kuna
  // }
  ]

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService) {
    this.productService.cartItems.subscribe(response => this.products = response);
  }

  ngOnInit(): void {
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

}
