// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  instagram_token: 'INSTAGRAM_TOKEN',
  stripe_token: 'STRIPE_PUBLISHABLE_KEY',
  paypal_token: 'AUkVO2EVizHSC25FKQeBQnjvJBqF9tLntT9YBCh0R3zTZGEPYDQVgoxfpipyUxzL9uuPa_y281v93unu',
  firebase: {
    apiKey: "AIzaSyBPZAttpHF0uEAFl25KX6KgfDLW2kArxYI",
    authDomain: "mebis-si.firebaseapp.com",
    databaseURL: "https://mebis-si.firebaseio.com",
    projectId: "mebis-si",
    storageBucket: "mebis-si.appspot.com",
    messagingSenderId: "556431652562",
    appId: "1:556431652562:web:68894b280bc76074f71f0b",
    measurementId: "G-7Z1XMD63SN"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
