import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of, Subject } from 'rxjs';
import { switchMap, map, take, tap } from 'rxjs/operators';
import { User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthService {

    user$: Observable<User>;

    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private router: Router
    ) {
      this.user$ = this.afAuth.authState.pipe(
        switchMap(user => {
            // Logged in
          if (user) {
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
          } else {
            // Logged out
            return of(null);
          }
        })
      )
    }

    // async googleSignin() {
    //   const provider = new auth.GoogleAuthProvider();
    //   const credential = await this.afAuth.signInWithPopup(provider);
    //   return this.updateUserData(credential.user, null);
    // }

    async signInWithEmailAndPassword(email, password) {
      const credential = await this.afAuth.signInWithEmailAndPassword(email, password);
      return this.updateLoginUserData(credential.user);
    }

    async createUserWithEmailAndPassword(form) {
      const credential = await this.afAuth.createUserWithEmailAndPassword(form.email, form.password);
      return this.updateUserData(credential.user, form);
    }
  
    private updateUserData(user, form: User) {
      // Sets user data to firestore on login
      const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
      const data = { 
        uid: user.uid, 
        email: user.email, 
        firstName: form.firstName,
        lastName: form.lastName,
        photoURL: user.photoURL
      } 
      return userRef.set(data, { merge: true }), this.router.navigate(['/']);
    }

    private updateLoginUserData(user) {
      // Sets user data to firestore on login
      const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
      const data = { 
        uid: user.uid, 
        email: user.email,
        photoURL: user.photoURL
      } 
      return userRef.set(data, { merge: true }), this.router.navigate(['/']);
    }
  
    async signOut() {
      await this.afAuth.signOut();
      this.router.navigate(['/']);
    }

    public isUserAdmin(): Observable<boolean> {
      let admin = new Subject<boolean>();
      this.user$.pipe(
        take(1),
        map(user => user.email.includes('@mebis.si') || user.email.includes('@varanox.com')), // <-- map to boolean
        tap(loggedIn => {
          if (!loggedIn) {
            // console.log('access denied')
            this.router.navigate(['/']);
            admin.next(false);
          } else {
            admin.next(true);
          }
        })
      )
     return admin;
    }

}